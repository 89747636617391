<template>
  <div class="filters">
    <!-- Фильтр по имени клиента -->
    <div class="filter-container">
      <div class="filter-title">Фильтр по имени клиента</div>
      <div class="filter">
        <div class="group">
          <svg class="icon" aria-hidden="true" viewBox="0 0 24 24">
            <g>
              <path
                d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z">
              </path>
            </g>
          </svg>
          <input placeholder="Search" type="search" v-model="clientName" class="input"
            @input="searchByClientName(clientName)" />
        </div>
      </div>
    </div>
    <!-- Фильтр по дате записи -->
    <!-- <div class="filter-container">
      <div class="filter-title">Фильтр по дате записи</div>
      <div class="filter">
        <Calendar
          v-model="date"
          @change="searchByDate"
          dateFormat="yy-mm-dd"
          inline
        />
        <div class="clear-date" @click="clearDate">Очистить</div>
      </div>
    </div> -->
  </div>

  <!-- Список записей -->
  <div class="calendar-container">
    <div class="calendar-header">
      <ButtonComponent @click="previousMonth" :name="'<'" :type="button"></ButtonComponent>
      <h2>{{ currentMonth + 1 }} {{ currentYear }}</h2>
      <ButtonComponent @click="nextMonth" :name="'>'" :type="button">></ButtonComponent>
    </div>
    <div class="calendar-grid">
      <div v-for="day in daysOfWeek" :key="day" class="calendar-day-name">
        {{ day }}
      </div>
      <div v-for="day in calendarDays" :key="day.date" class="calendar-day"
        :class="{ 'calendar-day-today': isToday(day.date) }">
        <span class="calendar-day-number">{{ day.day }}</span>
        <div v-if="day.appointments.length > 0" class="appointments-calendar">
          <div v-for="appointment in day.appointments" :key="appointment.id" class="appointment-mini"
            @click="showAppointmentDetails(appointment)">
            <p class="appointment-mini appointment-mini-master">
              {{ appointment.master.firstName }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Загрузка -->
  <!--  v-if="isLoading" -->
  <div v-if="isLoading" class="loaders">
    <div id="wifi-loader">
      <svg class="circle-outer" viewBox="0 0 86 86">
        <circle class="back" cx="43" cy="43" r="40"></circle>
        <circle class="front" cx="43" cy="43" r="40"></circle>
        <circle class="new" cx="43" cy="43" r="40"></circle>
      </svg>
      <svg class="circle-middle" viewBox="0 0 60 60">
        <circle class="back" cx="30" cy="30" r="27"></circle>
        <circle class="front" cx="30" cy="30" r="27"></circle>
      </svg>
      <svg class="circle-inner" viewBox="0 0 34 34">
        <circle class="back" cx="17" cy="17" r="14"></circle>
        <circle class="front" cx="17" cy="17" r="14"></circle>
      </svg>
      <div class="text" data-text="Поиск"></div>
    </div>
  </div>

  <div class="appointment-all" v-if="selectedDate">
    <div class="appointments appointment-details">
      <div class="appointment" v-for="appointment in filteredAppointments" :key="appointment.id">
        <div style="display: flex; justify-content: space-between;">
          <div v-if="editApplicationId !== appointment.id" class="appointment-name">
            {{ appointment.service }}
          </div>
          <div v-if="editApplicationId === appointment.id" class="appointment-text-fiel">
            <select name="" id="" v-model="editService">
              <option v-for="service in mainServices" :id="service.id" :value="service.name">
                {{ service.name }}
              </option>
            </select>
          </div>
          <div class="icon-edit-appointment" v-if="editApplicationId !== appointment.id">
            <svg width="30px" height="30px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
              @click="editAppointment(appointment.id, appointment.date, appointment.time, appointment.service)">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M21.2799 6.40005L11.7399 15.94C10.7899 16.89 7.96987 17.33 7.33987 16.7C6.70987 16.07 7.13987 13.25 8.08987 12.3L17.6399 2.75002C17.8754 2.49308 18.1605 2.28654 18.4781 2.14284C18.7956 1.99914 19.139 1.92124 19.4875 1.9139C19.8359 1.90657 20.1823 1.96991 20.5056 2.10012C20.8289 2.23033 21.1225 2.42473 21.3686 2.67153C21.6147 2.91833 21.8083 3.21243 21.9376 3.53609C22.0669 3.85976 22.1294 4.20626 22.1211 4.55471C22.1128 4.90316 22.0339 5.24635 21.8894 5.5635C21.7448 5.88065 21.5375 6.16524 21.2799 6.40005V6.40005Z"
                  stroke="#f4bc83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
                <path
                  d="M11 4H6C4.93913 4 3.92178 4.42142 3.17163 5.17157C2.42149 5.92172 2 6.93913 2 8V18C2 19.0609 2.42149 20.0783 3.17163 20.8284C3.92178 21.5786 4.93913 22 6 22H17C19.21 22 20 20.2 20 18V13"
                  stroke="#f4bc83" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path>
              </g>
            </svg>
          </div>

          <div class="icon-edit-appointment" v-if="editApplicationId === appointment.id">
            <svg @click="saveEditAppointment(appointment.id)" width="30px" height="30px" viewBox="0 0 24 24" fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
              <g id="SVGRepo_iconCarrier">
                <path d="M4 12.6111L8.92308 17.5L20 6.5" stroke="#f4bc83" stroke-width="2" stroke-linecap="round"
                  stroke-linejoin="round"></path>
              </g>
            </svg>
          </div>

        </div>
        <div v-if="editApplicationId !== appointment.id" class="appointment-text">
          <DateTimeFormatter :date="appointment.date" /> &nbsp;в
          {{ formatTime(appointment.time) }}
        </div>
        <div v-if="editApplicationId === appointment.id" class="appointment-text-edit">
          <input type="text" class="form__field" v-model="editDate">
        </div>
        <div v-if="editApplicationId === appointment.id" class="appointment-text-edit">
          <input type="text" class="form__field" v-model="editTime">
        </div>
        <div class="appointment-text">
          мастер {{ appointment.master.firstName }}
          {{ appointment.master.lastName }}
        </div>
        <div class="appointment-text">клиент {{ appointment.clientName }}</div>
        <div class="appointment-text">
          номер клиента
          <a class="client-number" href="javascript:void(0);" @click="copyPhoneNumber(appointment.clientPhone)">{{
            appointment.clientPhone }}</a>
        </div>
        <div class="main-price" v-for="mainService in mainServices" :key="mainService.id">
          <div class="title-price" v-if="mainService.name === appointment.service">
            {{ mainService.name }} цена:
          </div>
          <div class="price" v-if="mainService.name === appointment.service">
            <select v-model="appointment.selectedPriceId" @change="calculateTotalPrice(appointment)">
              <option v-for="(price, index) in mainService.prices" :key="price.id" :value="price.id">
                {{
                  index === mainService.prices.length - 1
                    ? "Длина более"
                    : "Длина"
                }}: {{ price.length }} см - Стоимость: {{ price.price }} руб.
              </option>
            </select>

            <!-- Поле для ввода пользовательской цены -->
            <div v-if="isCustomPrice(appointment)">
              <label for="customPrice">Введите вашу цену:</label>
              <input type="number" id="customPrice" v-model="appointment.customPrice"
                @input="calculateTotalPrice(appointment)" />
            </div>

            <div class="appointment-text">Густота:</div>
            <div class="mydict">
              <div>
                <label>
                  <input type="radio" v-model="appointment.density" value="0"
                    @change="calculateTotalPrice(appointment)" />
                  <span>До 5 см</span>
                </label>
                <label>
                  <input type="radio" v-model="appointment.density" value="500"
                    @change="calculateTotalPrice(appointment)" />
                  <span>От 5 см до 8 см</span>
                </label>
                <label>
                  <input type="radio" v-model="appointment.density" value="1000"
                    @change="calculateTotalPrice(appointment)" />
                  <span>От 8 см</span>
                </label>
              </div>
            </div>
            <!-- Дополнительные услуги -->
            <div class="additional-service">
              <div>Дополнительные услуги:</div>
              <select multiple v-model="appointment.selectedAdditionalServices"
                @change="calculateTotalPrice(appointment)">
                <option v-for="service in additionalServices" :key="service.id" :value="service">
                  {{ service.name }} - {{ service.price }} руб.
                </option>
              </select>
            </div>
            <!-- Общая цена -->
            <div class="additional-price">
              <div class="all-price">
                Общая цена: {{ appointment.allPrice }} руб.
              </div>
              <div class="master-earning">
                Заработок мастера:
                {{ ((masterEarning + appointment.allPrice) * 40) / 100 }} руб.
              </div>
            </div>
            <div class="buttons">
              <ButtonComponent :name="'Сохранить'" :type="button" @click="saveAppointment(appointment)" />
              <ButtonComponent :name="'Удалить'" :type="button" @click="deleteAppointment(appointment.id)" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <ModalInfo v-if="isModalVisible" :message="modalMessage" @close="isModalVisible = false" />
    <Toast />
  </div>
</template>

<script>
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import DateTimeFormatter from "@/components/DateTimeFormatter.vue";
import ButtonComponent from "@/components/ButtonComponent.vue";
import ModalInfo from "@/components/ModalInfo.vue";
import Calendar from "primevue/calendar";
import Toast from "primevue/toast";

export default {
  components: {
    DateTimeFormatter,
    ButtonComponent,
    ModalInfo,
    Calendar,
    Toast,
  },

  data() {
    return {
      appointments: [],
      role: null,
      mainServices: [],
      additionalServices: [],
      selectedPriceId: null,
      selectedAdditionalServices: [],
      selectedDensity: 0,
      clientName: null,
      masterEarning: 0,
      isModalVisible: false,
      modalMessage: "",
      date: null,
      selectedPrice: null,
      // Данные для календаря
      currentMonth: new Date().getMonth(),
      currentYear: new Date().getFullYear(),
      daysOfWeek: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
      selectedAppointment: null,
      selectedDate: null, // Добавляем selectedDate
      isLoading: false,

      editApplication: false,
      editService: "",
      editTime: "",
      editDate: "",
      editApplicationId: null,
    };
  },
  watch: {
    date: {
      immediate: false,
      handler(newDate) {
        if (newDate) {
          this.searchByDate();
        } else {
          this.appointments = [];
        }
      },
    },
  },
  computed: {
    calendarDays() {
      const daysInMonth = new Date(
        this.currentYear,
        this.currentMonth + 1,
        0
      ).getDate();
      const firstDayIndex = new Date(
        this.currentYear,
        this.currentMonth,
        1
      ).getDay();

      // Корректировка индекса первого дня недели:
      const firstDayCorrected = firstDayIndex === 0 ? 6 : firstDayIndex - 1;

      const days = [];

      let dayCounter = 1;
      for (let i = 0; i < 6 * 7; i++) {
        if (i >= firstDayCorrected && dayCounter <= daysInMonth) {
          const date = new Date(
            this.currentYear,
            this.currentMonth,
            dayCounter
          );
          days.push({
            day: dayCounter,
            date: date,
            appointments: this.appointments.filter(
              (a) => new Date(a.date).toDateString() === date.toDateString()
            ),
          });
          dayCounter++;
        } else {
          days.push({
            day: "",
            date: null,
            appointments: [],
          });
        }
      }

      return days;
    },
    filteredAppointments() {
      if (!this.selectedDate) return [];

      const selectedDateString = this.formatDate(this.selectedDate);
      return this.appointments.filter(
        (appointment) =>
          this.formatDate(new Date(appointment.date)) === selectedDateString
      );
    },
  },
  methods: {
    getAppointments() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      };
      this.isLoading = true;
      if (this.role[0].authority === "ADMIN") {

        const month = 12;
        const year = 2024;
        const page = 0;
        const size = 999;

        console.log(this.currentMonth)
        console.log(this.currentYear)

        axios.get(`https://leon-back-hiss.amvera.io/appointment?month=${this.currentMonth + 1}&year=${this.currentYear}&page=${page}&size=${size}`, { headers })
          .then((response) => {
            console.log(response.data.content)
            this.appointments = response.data.content.map((appointment) => {
              // Найти соответствующую цену для главной услуги
              const mainService = this.mainServices.find(
                (service) => service.name === appointment.service
              );
              if (mainService) {
                // 1. Проверяем, есть ли priceId в данных с backend
                if (appointment.priceId) {
                  appointment.selectedPriceId = appointment.priceId;
                } else {
                  // 2. Если priceId нет, ищем по priceForMainService
                  appointment.selectedPriceId =
                    mainService.prices.find(
                      (price) => price.price === appointment.priceForMainService
                    )?.id || null;
                }
              }
              // Преобразовать дополнительные услуги в массив
              appointment.selectedAdditionalServices =
                appointment.additionalServices.map((service) => ({
                  id: service.id,
                  name: service.name,
                  price: service.price,
                }));
              // Определить выбранную густоту
              const mainServicePrice =
                this.getSelectedPrice(appointment)?.price || 0; // Цена главной услуги
              const additionalServicePrice =
                appointment.selectedAdditionalServices
                  ? appointment.selectedAdditionalServices.reduce(
                    (total, service) => (total += service.price),
                    0
                  )
                  : 0; // Цена дополнительных услуг
              appointment.density =
                [0, 500, 1000].find(
                  (density) =>
                    density ===
                    appointment.allPrice -
                    appointment.priceForMainService -
                    additionalServicePrice
                ) || 0; // Вычисляем густоту

              console.log(
                appointment.allPrice,
                mainServicePrice,
                additionalServicePrice
              );
              this.isLoading = false;
              return appointment;
            });
            console.log(this.appointments);
          })
          .catch((error) => console.log(error));
      } else if (this.role[0].authority === "MASTER") {
        const page = 0;
        const size = 999;
        axios
          .get(`https://leon-back-hiss.amvera.io/appointment/master?month=${this.currentMonth + 1}&year=${this.currentYear}&page=${page}&size=${size}`, {
            headers,
          })
          .then((response) => {
            this.appointments = response.data.content.map((appointment) => {
              // Найти соответствующую цену для главной услуги
              const mainService = this.mainServices.find(
                (service) => service.name === appointment.service
              );
              if (mainService) {
                // Находим ID цены и сохраняем его в selectedPriceId
                appointment.selectedPriceId =
                  mainService.prices.find(
                    (price) => price.price === appointment.priceForMainService
                  )?.id || null;
              }
              // Преобразовать дополнительные услуги в массив
              appointment.selectedAdditionalServices =
                appointment.additionalServices.map((service) => ({
                  id: service.id,
                  name: service.name,
                  price: service.price,
                }));
              // Определить выбранную густоту
              const mainServicePrice =
                this.getSelectedPrice(appointment)?.price || 0; // Цена главной услуги
              const additionalServicePrice =
                appointment.selectedAdditionalServices
                  ? appointment.selectedAdditionalServices.reduce(
                    (total, service) => (total += service.price),
                    0
                  )
                  : 0; // Цена дополнительных услуг
              appointment.density =
                [0, 500, 1000].find(
                  (density) =>
                    density ===
                    appointment.allPrice -
                    mainServicePrice -
                    additionalServicePrice
                ) || 0; // Вычисляем густоту
              this.isLoading = false;
              return appointment;
            });
            console.log(this.appointments);
          })
          .catch((error) => console.log(error));
      }
    },
    formatTime(time) {
      return time.slice(0, 5);
    },
    getSelectedPrice(appointment) {
      for (const service of this.mainServices) {
        if (service.name === appointment.service) {
          return service.prices.find(
            (price) => price.id === appointment.selectedPriceId
          );
        }
      }
      return null;
    },
    isCustomPrice(appointment) {
      const selectedPrice = this.getSelectedPrice(appointment);
      return selectedPrice && selectedPrice.price === 0;
    },
    getMainService() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      axios
        .get("https://leon-back-hiss.amvera.io/service", { headers })
        .then((response) => {
          this.mainServices = response.data;
        })
        .catch((error) => console.log(error));
    },
    getAdditionalServices() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      axios
        .get("https://leon-back-hiss.amvera.io/additional-service", {
          headers,
        })
        .then((response) => {
          this.additionalServices = response.data;
        })
        .catch((error) => console.log(error));
    },
    calculateTotalPrice(appointment) {
      const mainServicePrice = this.isCustomPrice(appointment)
        ? parseFloat(appointment.customPrice) || 0
        : this.getSelectedPrice(appointment)?.price || 0;
      const densityPrice = parseInt(appointment.density, 10) || 0;
      const additionalServicePrices = appointment.selectedAdditionalServices
        ? appointment.selectedAdditionalServices.reduce(
          (total, service) => (total += service.price),
          0
        )
        : 0;
      appointment.allPrice =
        mainServicePrice + densityPrice + additionalServicePrices;
    },
    getPriceText(price, index) {
      if (index === this.mainService.prices.length - 1) {
        return `Длина более: ${price.length} см - Стоимость: ${price.price} руб.`;
      } else {
        return `Длина до: ${price.length} см - Стоимость: ${price.price} руб.`;
      }
    },
    async saveAppointment(appointment) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      };

      try {
        const selectedPrice = this.getSelectedPrice(appointment);

        const priceForMainService = this.isCustomPrice(appointment)
          ? parseFloat(appointment.customPrice) || 0 // Используем customPrice, если он есть
          : selectedPrice?.price || 0;

        const updatedAppointment = {
          date: appointment.date,
          time: appointment.time,
          clientName: appointment.clientName,
          service: appointment.service,
          clientPhone: appointment.clientPhone,
          master: {
            id: appointment.master.id,
            firstName: appointment.master.firstName,
            lastName: appointment.master.lastName,
          },
          allPrice: appointment.allPrice,
          priceForMainService: priceForMainService,
          priceForAdditionalService: appointment.selectedAdditionalServices
            ? appointment.selectedAdditionalServices.reduce(
              (total, service) => (total += service.price),
              0
            )
            : 0,
          additionalServices: appointment.selectedAdditionalServices
            ? appointment.selectedAdditionalServices.map((service) => ({
              id: service.id,
            }))
            : [],
        };

        // console.log(updatedAppointment);

        await axios.put(
          `https://leon-back-hiss.amvera.io/appointment/${appointment.id}`,
          updatedAppointment,
          { headers }
        );

        // Обновляем данные записи в массиве appointments:
        const index = this.appointments.findIndex(
          (item) => item.id === appointment.id
        );
        if (index !== -1) {
          this.appointments[index] = {
            ...this.appointments[index],
            ...updatedAppointment,
          };
          this.showModal("Данные успешно отправлены");
        }
      } catch (error) {
        console.error(error);
        this.showModal("Ошибка при отправке данных");
      }
    },
    searchByClientName(name) {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      const previousSelectedValues = this.appointments.reduce(
        (acc, appointment) => {
          acc[appointment.id] = {
            selectedPriceId: appointment.selectedPriceId,
            selectedAdditionalServices: appointment.selectedAdditionalServices,
            density: appointment.density,
          };
          return acc;
        },
        {}
      );

      if (name.length > 0) {
        axios
          .get(
            "https://leon-back-hiss.amvera.io/appointment/client-name?name=" +
            name,
            {
              headers,
            }
          )
          .then((response) => {
            this.appointments = response.data.map((appointment) => {
              const previousValues =
                previousSelectedValues[appointment.id] || {};
              const mainService = this.mainServices.find(
                (service) => service.name === appointment.service
              );
              if (mainService) {
                appointment.selectedPriceId =
                  previousValues.selectedPriceId ||
                  mainService.prices.find(
                    (price) => price.price === appointment.priceForMainService
                  )?.id ||
                  null;
              }
              appointment.selectedAdditionalServices =
                previousValues.selectedAdditionalServices ||
                appointment.additionalServices.map((service) => ({
                  id: service.id,
                  name: service.name,
                  price: service.price,
                }));
              appointment.density = previousValues.density || 0;
              const mainServicePrice =
                this.getSelectedPrice(appointment)?.price || 0;
              const additionalServicePrice =
                appointment.selectedAdditionalServices.reduce(
                  (total, service) => (total += service.price),
                  0
                );
              appointment.density =
                previousValues.density ||
                [0, 500, 1000].find(
                  (density) =>
                    density ===
                    appointment.allPrice -
                    mainServicePrice -
                    additionalServicePrice
                ) ||
                0;
              return appointment;
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        this.getAppointments();
      }
    },
    searchByDate() {
      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      if (this.date) {
        const formattedDate = this.formatDate(this.date);
        // console.log("Выбранная дата:", formattedDate);

        axios
          .get(
            `https://leon-back-hiss.amvera.io/appointment/date?date=${formattedDate}`,
            {
              headers,
            }
          )
          .then((response) => {
            // console.log("Ответ сервера:", response.data);
            this.appointments = response.data;
          })
          .catch((error) => {
            console.log(error);
            this.appointments = [];
          });
      } else {
        // console.log("Дата не выбрана");
        this.getAppointments();
      }
    },
    clearDate() {
      this.date = null;
      this.getAppointments();
    },
    formatDate(date) {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    },
    showModal(message) {
      this.modalMessage = message;
      this.isModalVisible = true;
      setTimeout(() => {
        this.isModalVisible = false;
        this.modalMessage = "";
      }, 2000);
    },
    deleteAppointment(id) {
      if (confirm("Вы уверены, что хотите удалить эту запись?")) {
        // Добавляем окно подтверждения
        const token = localStorage.getItem("token");
        const headers = {
          Authorization: `Bearer ${token}`,
        };

        axios
          .delete("https://leon-back-hiss.amvera.io/appointment/" + id, {
            headers,
          })
          .then((response) => {
            this.appointments = this.appointments.filter(
              (appointment) => appointment.id !== id
            );
          })
          .catch((error) => console.log(error));
      }
    },
    editAppointment(id, date, time, service) {
      this.editApplicationId = id;
      this.editApplication = true;
      this.editService = service;
      this.editDate = date;
      this.editTime = time;
    },
    saveEditAppointment(id) {
      // Подтверждение перед сохранением изменений
      const confirmMessage = `Вы точно хотите поменять запись? 
    Услуга: ${this.editService}
    Дата: ${this.editDate}
    Время: ${this.editTime}`;

      if (!window.confirm(confirmMessage)) {
        // Если пользователь нажал "Отмена", отменяем действие
        return;
      }

      const token = localStorage.getItem("token");
      const headers = {
        Authorization: `Bearer ${token}`,
      };

      // Данные для обновления записи
      const data = {
        service: this.editService,
        date: this.editDate,
        time: this.editTime,
      };

      // Сбрасываем режим редактирования
      this.editApplication = false;

      // Логируем данные перед запросом (для проверки)
      console.log(id, data);

      // Отправляем PATCH-запрос
      axios
        .patch(`https://leon-back-hiss.amvera.io/appointment/${id}`, data, { headers })
        .then((response) => {
          console.log("Appointment updated successfully:", response.data);
          this.getAppointments();
          this.editApplicationId = null;
          this.showModal("Данные успешно отправлены");
        })
        .catch((error) => {
          console.error("Error updating appointment:", error.response || error);
          this.showModal("Ошибка при отправке данных");
        });
    },

    // Всё для календаря
    previousMonth() {
      if (this.currentMonth === 0) {
        this.currentMonth = 11;
        this.currentYear--;
        this.getAppointments()
      } else {
        this.currentMonth--;
        this.getAppointments()
      }
    },
    nextMonth() {
      if (this.currentMonth === 11) {
        this.currentMonth = 0;
        this.currentYear++;
        this.getAppointments()
      } else {
        this.currentMonth++;
        this.getAppointments()
      }
    },
    isToday(date) {
      if (!date) return false;
      const today = new Date();
      return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
      );
    },
    formatDateCalendar(dateString, monthFormat = "short") {
      const date = new Date(dateString);
      return date.toLocaleDateString("ru-RU", { month: monthFormat });
    },
    formatTimeCalendar(timeString) {
      const time = timeString.slice(0, 5);
      return time;
    },
    showAppointmentDetails(appointment) {
      this.selectedDate = new Date(appointment.date); // Сохраняем дату при клике
    },
    copyPhoneNumber(clientPhone) {
      navigator.clipboard.writeText(clientPhone);
      this.showSuccess();
    },
    showSuccess() {
      this.$toast.add({
        severity: "success",
        summary: "Номер скопирован!",
        detail: "Номер телефона скопирован в буфер обмена!",
        life: 3000,
      });
    },
  },
  mounted() {
    this.getAppointments();
    this.getMainService();
    this.getAdditionalServices();
  },
  created() {
    const token = localStorage.getItem("token");
    if (token) {
      const decodedToken = jwtDecode(token);
      this.role = decodedToken.role;
    }
  },
};
</script>

<style scoped>
.buttons {
  display: flex;
  justify-content: space-between;
}

.appointments {
  display: grid;
  gap: 0;
  padding: 0 12px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.appointment-name {
  font-size: 23px;
  color: #f4bc83;
}

.appointment {
  border: 2px solid #f4bc83;
  border-radius: 10px;
  padding: 12px;
  background-color: rgba(56, 56, 56, 0.6);
  backdrop-filter: blur(30px);
  margin-bottom: 20px;
  margin-right: 20px;
}

.appointment-text {
  margin: 10px 0;
  font-size: 18px;
  display: flex;
}

.client-number {
  margin-left: 10px;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #2a2a2a;
  color: #fff;
  appearance: none;
  margin-top: 10px;
}

select:focus {
  border-color: #f4bc83;
  outline: none;
}

option {
  background-color: #2a2a2a;
  color: #fff;
}

option:checked,
option:hover {
  background-color: #f4bc83;
}

/* radio button */
:focus {
  outline: 0;
  border-color: #f4bc83;
  box-shadow: 0 0 0 4px #f4bc83;
}

.mydict div {
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.5rem;
  justify-content: center;
  margin: 20px 0;
}

.mydict input[type="radio"] {
  clip: rect(0 0 0 0);
  clip-path: inset(100%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

.mydict input[type="radio"]:checked+span {
  box-shadow: 0 0 0 0.0625em #f4bc83;
  background-color: #383838;
  z-index: 1;
  color: #f4bc83;
}

label span {
  display: block;
  cursor: pointer;
  background-color: #383838;
  padding: 0.375em 0.75em;
  position: relative;
  margin-left: 0.0625em;
  box-shadow: 0 0 0 0.0625em #383838;
  letter-spacing: 0.05em;
  color: #7e6144;
  text-align: center;
  transition: background-color 0.5s ease;
}

label:first-child span {
  border-radius: 0.375em 0 0 0.375em;
}

label:last-child span {
  border-radius: 0 0.375em 0.375em 0;
}

/*  */

/* Цена */
.additional-price {
  margin-bottom: 20px;
}

.all-price {
  color: #f4bc83;
  font-size: 22px;
  margin-top: 10px;
}

/* Поиск и фильры */

.filter-title {
  font-size: 22px;
  color: #f4bc83;
}

.filters {
  display: flex;
  justify-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.filter {
  display: grid;
  justify-items: end;
}

.clear-date {
  font-size: 18px;
  cursor: pointer;
}

.filter-container {
  display: grid;
  justify-items: center;
}

.group {
  display: flex;
  line-height: 28px;
  align-items: center;
  position: relative;
  max-width: 190px;
  margin: 20px 0;
}

.icon-edit-appointment {
  cursor: pointer;
  width: 30px !important;
  height: 30px !important;
}

.input {
  height: 40px;
  line-height: 28px;
  /* padding: 0 1rem; */
  width: 100%;
  padding-left: 2.5rem;
  border: 2px solid transparent;
  border-radius: 8px;
  outline: none;
  /* background-color: #D9E8D8; */
  color: #0d0c22;
  box-shadow: 0 0 5px #383838, 0 0 0 10px #f4bc83;
  transition: 0.3s ease;
}

.input::placeholder {
  color: #777;
}

.icon {
  position: absolute;
  left: 1rem;
  fill: #777;
  width: 1rem;
  height: 1rem;
}

/* Стили для календаря */
.calendar-container {
  display: flex;
  flex-direction: column;
  max-width: 500px;
  margin: 0 auto;
  font-family: sans-serif;
  margin-bottom: 50px;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  /* background-color: #f2f2f2; */
}

.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendar-day-name {
  text-align: center;
  padding: 5px;
  border-bottom: 1px solid #f4bc83;
}

.calendar-day {
  border: 1px solid #f4bc83;
  padding: 5px;
  min-height: 50px;
  overflow-y: auto;
}

.calendar-day-today {
  /* background-color: #f0f0f0; */
}

.calendar-day-number {
  font-weight: bold;
}

.appointment-mini {
  font-size: 14px;
  display: grid;
  /* justify-content: end; */
  background-color: #23be76;
  border-radius: 5px;
  margin-bottom: 3px;
}

.appointments-calendar {}

/* Загрузка */

.loaders {
  /* left: 150px; */
  /* width: 200px; */
  /* height: 200px; */
  /* position: relative; */
  /* top: 100px; */
  /* right: 20px; */
  display: grid;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 170px;
  margin: 0;
  padding: 0;
}

#wifi-loader {
  --background: #62abff;
  --front-color: #f4bc83;
  --back-color: #c3c8de;
  --text-color: #fff;
  width: 64px;
  height: 64px;
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}

#wifi-loader svg circle {
  position: absolute;
  fill: none;
  stroke-width: 6px;
  stroke-linecap: round;
  stroke-linejoin: round;
  transform: rotate(-100deg);
  transform-origin: center;
}

#wifi-loader svg circle.back {
  stroke: var(--back-color);
}

#wifi-loader svg circle.front {
  stroke: var(--front-color);
}

#wifi-loader svg.circle-outer {
  height: 86px;
  width: 86px;
}

#wifi-loader svg.circle-outer circle {
  stroke-dasharray: 62.75 188.25;
}

#wifi-loader svg.circle-outer circle.back {
  animation: circle-outer135 1.8s ease infinite 0.3s;
}

#wifi-loader svg.circle-outer circle.front {
  animation: circle-outer135 1.8s ease infinite 0.15s;
}

#wifi-loader svg.circle-middle {
  height: 60px;
  width: 60px;
}

#wifi-loader svg.circle-middle circle {
  stroke-dasharray: 42.5 127.5;
}

#wifi-loader svg.circle-middle circle.back {
  animation: circle-middle6123 1.8s ease infinite 0.25s;
}

#wifi-loader svg.circle-middle circle.front {
  animation: circle-middle6123 1.8s ease infinite 0.1s;
}

#wifi-loader svg.circle-inner {
  height: 34px;
  width: 34px;
}

#wifi-loader svg.circle-inner circle {
  stroke-dasharray: 22 66;
}

#wifi-loader svg.circle-inner circle.back {
  animation: circle-inner162 1.8s ease infinite 0.2s;
}

#wifi-loader svg.circle-inner circle.front {
  animation: circle-inner162 1.8s ease infinite 0.05s;
}

#wifi-loader .text {
  position: absolute;
  bottom: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: lowercase;
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.2px;
}

#wifi-loader .text::before,
#wifi-loader .text::after {
  content: attr(data-text);
}

#wifi-loader .text::before {
  color: var(--text-color);
}

#wifi-loader .text::after {
  color: var(--front-color);
  animation: text-animation76 3.6s ease infinite;
  position: absolute;
  left: 0;
}

@keyframes circle-outer135 {
  0% {
    stroke-dashoffset: 25;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 301;
  }

  80% {
    stroke-dashoffset: 276;
  }

  100% {
    stroke-dashoffset: 276;
  }
}

@keyframes circle-middle6123 {
  0% {
    stroke-dashoffset: 17;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 204;
  }

  80% {
    stroke-dashoffset: 187;
  }

  100% {
    stroke-dashoffset: 187;
  }
}

@keyframes circle-inner162 {
  0% {
    stroke-dashoffset: 9;
  }

  25% {
    stroke-dashoffset: 0;
  }

  65% {
    stroke-dashoffset: 106;
  }

  80% {
    stroke-dashoffset: 97;
  }

  100% {
    stroke-dashoffset: 97;
  }
}

@keyframes text-animation76 {
  0% {
    clip-path: inset(0 100% 0 0);
  }

  50% {
    clip-path: inset(0);
  }

  100% {
    clip-path: inset(0 0 0 100%);
  }
}

@media screen and (max-width: 1440px) {
  .appointments {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

@media screen and (max-width: 768px) {
  .appointments {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  label span {
    font-size: 13px;
  }
}

@media screen and (max-width: 480px) {
  .appointment {
    margin-right: 0;
  }

  .appointments {
    justify-content: center;
  }

  .appointments {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
}

@media screen and (max-width: 320px) {
  label span {
    font-size: 12px;
  }
}
</style>
